import { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { AuthHandlerContext } from '../../services/context/authHandler.context';
import {
  UnprotectedRoutes,
  WizardCreationRoute,
  WebsiteRoutes,
  SuperAdminRoutes,
} from '../constants/routes';
import { httpClient } from '../../services/httpClient/httpClient';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getVenue, getProperties, setSelectedVenue } from '../../store/slices/venueSlice';
import { defaultPagination, UserRoles } from '../constants/constants';
import { getVenueList } from '../../store/slices/organizationVenueSlice';
import { getPrisingPlan } from '../../store/slices/BillingSlice';
import {
  getActiveIncentiveCampaign,
  getCampaigns,
  getRewardCardPreview,
} from '../../store/slices/campaignsSlice';
import {
  DEFAULT_REWARD_HEADER,
  DEFAULT_REWARD_NAME,
} from '../../pages/NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { getConnectedAccount } from '../../store/slices/gbpSlice';
import { useClientType } from '../../services/hooks/useClientType';
import { isUWMClient } from '../../utils/isUWMClient';
import { getMe } from '../../store/slices/meSlice';

type Props = {
  children?: ReactNode;
};

export const AuthHandler: FC = (props: Props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { shouldLogout } = useTypedSelector((state) => state.venue);
  const dispatch = useAppDispatch();
  const { auth, id, role, accounts, name } = useTypedSelector((state) => state.me);
  const { programId } = useTypedSelector((state) => state.venueCreation.program_payload);
  const isLoggedUser = httpClient.isLoggedIn();
  // const { selectedVenueId, venue } = useTypedSelector((state) => state.venue);

  const { isLoading, items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const isSuperAdmin = !isLoading && accounts > 1 ? true : false;
  const isSocialVenueAdmin = ([UserRoles.admin_role] as string[]).includes(role);
  const hasAccessToAccounts = items.length > 0;
  const selectedVenuId = useTypedSelector((state) => state.venue.selectedVenueId);
  const {
    activeCampaign,
    rewardCard,
    isRewardCardLoading,
    isLoaded: isCampaignsLoaded,
    uwmRedirectCampaign,
  } = useTypedSelector((state) => state.campaigns);
  const { logo } = useTypedSelector((state) => state.venue.venue);

  const { isUwmClient } = useClientType();

  useEffect(() => {
    if (selectedVenuId) {
      dispatch(getVenue({ id: selectedVenuId }));
      dispatch(
        getPrisingPlan({
          venueId: selectedVenuId,
        }),
      );
      dispatch(getProperties({ id: selectedVenuId }));
      dispatch(getActiveIncentiveCampaign(selectedVenuId));
      dispatch(
        getCampaigns({
          accountId: selectedVenuId,
          pageable: { page: 0, sort: defaultPagination.sort },
        }),
      );
      dispatch(
        getConnectedAccount({
          accountId: selectedVenuId,
        }),
      );
      dispatch(getMe({ id }));
    }
  }, [selectedVenuId, dispatch, id]);

  useEffect(() => {
    if (items[0] && !pathname.includes(WebsiteRoutes.RewardCampaigns)) {
      dispatch(
        getCampaigns({
          accountId: items[0].id,
          pageable: { page: 0, sort: defaultPagination.sort },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (isLoggedUser && !rewardCard && !isRewardCardLoading) {
      dispatch(
        getRewardCardPreview({
          logoUrl: logo,
          userName: name,
          rewardHeader: DEFAULT_REWARD_HEADER,
          rewardTitle: DEFAULT_REWARD_NAME,
          cardStyle: '',
          contact: '',
          companyName: name,
          date: format(new Date(), 'P'),
        }),
      );
    }
  }, [
    activeCampaign?.rewardTitle,
    activeCampaign?.isNullReward,
    dispatch,
    logo,
    name,
    isRewardCardLoading,
    rewardCard,
    isLoggedUser,
  ]);

  const { groupId } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { activeDistributor } = useTypedSelector((state) => state.distributors);
  const { activeAffiliate } = useTypedSelector((state) => state.affiliates);

  useEffect(() => {
    if (isLoggedUser) {
      dispatch(
        getVenueList({
          groupId: activeDistributor
            ? activeDistributor.groupId
            : activeAffiliate
            ? activeAffiliate.groupId
            : groupId,
          params: {
            size: 50,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedUser, activeDistributor, activeAffiliate]);

  useEffect(() => {
    // Redirect user to login page if not logged in
    if (!isLoggedUser && !Object.values<string>(UnprotectedRoutes).includes(pathname)) {
      history.push(isUWMClient() ? UnprotectedRoutes.SessionTimeout : UnprotectedRoutes.Login);
    }
  }, [auth, history, isLoggedUser, pathname, hasAccessToAccounts, isSocialVenueAdmin]);

  useEffect(() => {
    // TODO: Show login errors
    // TODO: add check for user role, redirect to dashboard if not right role
    // TODO: for passwords: use check if user exist, to allow submit password
    // TODO: api integration for upload assets.
    // TODO: api filters integration
    // TODO: api venu creation
    // TODO: clean up authHandler

    if (!isCampaignsLoaded) {
      return;
    }

    if (
      (pathname === UnprotectedRoutes.Login || pathname === UnprotectedRoutes.ResetPassword) &&
      isLoggedUser &&
      hasAccessToAccounts &&
      accounts
    ) {
      // dispatch get organizations
      // dispatch get markets
      // push social venu admin default landing page

      // history.push(SuperAdminRoutes.Performance);
      dispatch(setSelectedVenue({ id: items[0].id }));

      if (
        isUwmClient &&
        localStorage.getItem('showUwmLaunchModal') === 'true' &&
        accounts === 1 &&
        uwmRedirectCampaign
      ) {
        history.push(`${WebsiteRoutes.RewardCampaigns}/${uwmRedirectCampaign.id}`);
      } else {
        history.push(WebsiteRoutes.ProfileAccounts);
      }
    } else if (
      hasAccessToAccounts &&
      pathname === UnprotectedRoutes.Login &&
      isLoggedUser &&
      accounts
    ) {
      dispatch(getVenue({ id: items[0].id }));
      dispatch(getProperties({ id: items[0].id }));

      dispatch(setSelectedVenue({ id: items[0].id }));
      history.push(WebsiteRoutes.RewardCampaigns);
    }
    // if (selectedVenueId) {
    //   dispatch(getVenue({ id: selectedVenueId }));
    //   dispatch(getProperties({ id: selectedVenueId }));
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    auth,
    id,
    hasAccessToAccounts,
    isSuperAdmin,
    isSocialVenueAdmin,
    accounts,
    isCampaignsLoaded,
    isUwmClient,
  ]);

  useEffect(() => {
    // check if the route is protected
    // if so validate the program id or auth token exist
    if (!Object.values(UnprotectedRoutes).filter((item) => item === pathname).length) {
      if (Object.values(WebsiteRoutes).filter((item) => item === pathname).length) {
        if (!isLoggedUser) {
          setIsLoggedIn(isLoggedUser);
          history.push(isUWMClient() ? UnprotectedRoutes.SessionTimeout : UnprotectedRoutes.Login);
        } else {
          setIsLoggedIn(isLoggedUser);
        }
      } else if (Object.values(WizardCreationRoute).filter((item) => item === pathname).length) {
        if (programId) {
          //TODO
          //redirect wizard instructions page
          // if (pathname !== WizardCreationRoute.SetupWizard) {
          //   history.push(WizardCreationRoute.SetupWizard);
          // }
        } else {
          history.push(UnprotectedRoutes.UnAuthorized);
        }
      } else if (Object.values(SuperAdminRoutes).filter((item) => item === pathname).length) {
        if (!isLoggedUser) {
          setIsLoggedIn(isLoggedUser);
          history.push(UnprotectedRoutes.Login);
        } else {
          setIsLoggedIn(isLoggedUser);
        }
      }
    } else {
      if (programId && pathname !== WizardCreationRoute.SetupWizard) {
        history.push(WizardCreationRoute.SetupWizard);
      }
    }
    if (
      (!isSuperAdmin || !isSocialVenueAdmin) &&
      Object.values(SuperAdminRoutes).filter((item) => item === pathname).length
    ) {
      history.push(WebsiteRoutes.RewardCampaigns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, programId, id]);

  useEffect(() => {
    if (shouldLogout) {
      history.push(isUWMClient() ? UnprotectedRoutes.SessionTimeout : UnprotectedRoutes.Login);
    }
  }, [shouldLogout, history]);

  return (
    <AuthHandlerContext.Provider value={isLoggedIn}>{props.children}</AuthHandlerContext.Provider>
  );
};

// TODO move to dashboard container
// useEffect(() => {
//   const pingWebAppVenue = async (subdomain: string) => {
//     try {
//       await fetch(`https://${subdomain}${SettingsPageStrings.DomainName}`, {
//         method: 'GET',
//         mode: 'no-cors',
//       });
//       dispatch(setIsWebAppAvailable(true));
//     } catch (err) {
//       dispatch(setIsWebAppAvailable(false));
//     }
//   };
//   if (selectedVenueId && venue) {
//     pingWebAppVenue(venue.subdomain);
//   } else {
//     setIsWebAppAvailable(false);
//   }
// }, [selectedVenueId, venue, dispatch]);
